/* ----------------------------------------------- */
/* NAVBAR */
/* ----------------------------------------------- */
.navbar-box {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 100000;
}

.navbar {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  color: #000;
  font-weight: bold;
  align-items: center;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.navbar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 100px;
  margin-top: 10px;
}

.navbar h3 {
  color: white;
}

.social {
  cursor: pointer;
}

.navlink li {
  display: inline-block;
}

.navlink li .active button {
  color: #fff;
  background: #000;
}

.navbar-btn {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: large;
  padding: 6px 20px;
  background-color: rgb(0, 166, 255);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.navbar-btn:hover {
  background-color: #000;
  color: #fff;
}

.hamburger {
  display: none;
  cursor: pointer;
  list-style: none;
}

.hamburger li:nth-child(2) {
  width: 23px;
}

.hamburger li:nth-child(3) {
  width: 15px;
}

/* ----------------------------------------------- */
/* MOBILE HAMBURGER MENU */
/* ----------------------------------------------- */
@media only screen and (max-width: 1023px) {
  .list-container {
    display: flex;
  }

  .navbar .navlink li {
    margin: 0 0 0 48px;
  }
  .navbar h3 {
    color: white;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 880px) {
  .navbar {
    height: 64px;
  }

  .navbar .navlink li {
    display: none;
    margin: 0 0 0 0;
  }

  .list-container {
    display: block;
  }

  .link-container {
    display: flex !important;
    position: fixed;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    top: 0;
    right: 0;
    height: 0;
    opacity: 0;
    width: 100%;
    transition: 0.5s;
  }

  .link-container .navlink {
    align-items: center;
    display: block;
    justify-content: center;
  }

  .navbar.active .link-container {
    height: 100vh;
    opacity: 1;
  }

  .hamburger {
    display: block;
    right: 24px;
    top: 0px;
    height: 64px;
    align-items: center;
    position: absolute;
    cursor: pointer;
    display: flex;
    transition: 0.5s;
  }

  .line {
    width: 30px;
    height: 3px;
    margin: 6px;
    background: white;
    -webkit-transition: 1s;
    transition: 1s;
  }

  .navbar.active {
    display: block;
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    height: 100vh;
  }

  .navbar.active .logo {
    display: none;
  }

  .navbar.active .list-container .navlink li {
    display: block;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 5vh;
    font-size: 1.5em;
  }

  .hamburger.active {
    transform: translateY(10px);
  }

  .hamburger.active li:nth-child(1) {
    -webkit-transform: rotate(405deg) translate(14px);
    transform: rotate(405deg) translate(14px);
    height: 5px;
    width: 50px;
    background-color: #818181;
    border-radius: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .hamburger.active li:nth-child(2) {
    opacity: 0;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
    -webkit-transition: 1s ease;
    transition: 1s ease;
  }
  .hamburger.active li:nth-child(3) {
    -webkit-transform: rotate(-405deg) translate(14px);
    transform: rotate(-405deg) translate(14px);
    height: 5px;
    width: 50px;
    background-color: #818181;
    border-radius: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}


/* <input type="checkbox" className="menu-btn" id="menu-btn" />
        <label className="menu-icon" for="menu-btn">
          <span className="nav-icon"></span>
        </label> */