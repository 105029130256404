@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #17171f;
  font-family: "Poppins", sans-serif;
}

body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
